document.addEventListener('alpine:init', () => {
    window.Alpine.data('selectData', (selected, placeholder, disabled, options, isLivewire, name, searchEnabled) => ({
        selected: selected,
        selectedLabel: '',
        placeholder: placeholder,
        disabled: disabled,
        show: false,
        // the html is horrendous, but I see no other way
        options: [],
        name: name,
        focused: false,

        searchInput: '',
        searchEnabled: searchEnabled,

        init() {
            if (options) {
                this.options = options;
            }

            // Set initial label
            this.setLabel()

            // Cause an onInput event when 'selected' is changed
            this.$watch('selected', (value) => {
                this.$refs.flow_input?.oninput?.(value)
                if (this.$refs.flow_input.dataset.reactiveName) {
                    this.$dispatch(`on-flow-input-${this.$refs.flow_input.dataset.reactiveName}-change-livewire`, {
                        value
                    })
                }

                this.setLabel()
            });

            if (this.$refs.flow_input?.dataset.reactiveName) {
                window.addEventListener(`on-flow-input-${this.$refs.flow_input.dataset.reactiveName}-options-change`, (e) => {
                    const options = e.detail?.options || []
                    this.options = options
                })
                window.addEventListener(`on-flow-input-${this.$refs.flow_input.dataset.reactiveName}-change`, (e) => {
                    const value = e.detail?.value || null
                    this.selected = value

                    this.setLabel()
                })
            }

            this.$watch('searchInput', () => this.position = 0);
        },
        checkOffscreen() {

            const dropdown = this.$refs.dropdown;
            if (!dropdown) {
                return;
            }

            if (!this.show) {
                dropdown.style = 'display: none;';
                return;
            } else {
                dropdown.style = 'display: block;';
            }

            const input = this.$refs.input;
            const inputRect = input.getBoundingClientRect();
            const dropdownRect = dropdown.getBoundingClientRect();
            const inputBottom = inputRect.top + inputRect.height;
            const distance = getComputedStyle(dropdown).getPropertyValue('--f-select-input--dropdown-top').replace(/\s/g, '');

            if (inputBottom + dropdownRect.height + 8 > window.innerHeight && this.show) {
                dropdown.style = `bottom: calc(${inputRect.height}px + ${distance}); display: block;`;
            }

            if (inputBottom + dropdownRect.height + 8 <= window.innerHeight && this.show) {
                dropdown.style = 'top: calc(100% + var(--f-select-input--dropdown-top));';
            }
        },
        setLabel() {
            if (!this.selected) {
                this.selectedLabel = this.placeholder
                return
            }
            this.selectedLabel = this.options.find(val => val.key.toString() === this.selected.toString())?.value;
        },
        showHideList(show) {
            this.searchInput = '';
            if (this.disabled) {
                return;
            }

            if (show !== undefined) {
                this.show = show;
            } else {
                this.show = !this.show;
            }
            if (this.show  === true) {
                this.$nextTick(() => {
                    if (this.searchEnabled) {
                        this.$refs.searchInput.focus();
                    } else {
                        this.$refs.flow_input.focus();
                    }
                });
            }

            this.checkOffscreen();
        },
        setSelected(selected) {
            this.selected = selected?.key;
            this.selectedLabel = selected?.value;

            this.$refs.flow_input.focus();

            this.showHideList(false);
        },

        getOptions() {
          if (!this.searchEnabled) {
              return this.options;
          }

          return this.options.filter(option => {
             return option.value.toLowerCase().includes(this.searchInput.toLowerCase());
          });
        },

        /**
         * Navigating with arrow keys
         **/
        optionIndex: 0,
        isNavigatingOptions: true,
        navigateOptions(direction) {
            this.isNavigatingOptions = false;
            if (direction === 'down') {
                this.optionIndex++;
                if (this.optionIndex > this.getOptions().length - 1) {
                    this.optionIndex = 0;
                }
            } else {
                this.optionIndex--;
                if (this.optionIndex < 0) {
                    this.optionIndex = this.getOptions().length - 1;
                }
            }

            this.ensureOptionInView( this.getOptions()?.[this.optionIndex]?.key);
        },
        chooseOption() {
            this.setSelected( this.getOptions()?.[this.optionIndex]);
        },
        ensureOptionInView(id) {
            const el = document.getElementById(`${name}__${id}`);
            const div = this.$refs.dropdown;

            const padding = window.getComputedStyle(div)?.getPropertyValue('padding-top')?.match(/\d+/)[0];

            if (el) {
                div.scrollTo({
                    top: el.offsetTop - el.getBoundingClientRect().height - (padding ?? 0) // Take into account element height + dropdown padding
                });
            }
        }
    }));
});
