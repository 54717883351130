import ApexCharts from 'apexcharts'
import {mergeChartOptions, numberFormat} from '../helpers';

export const donutChart = () => {
    return {
        chart: null,
        chartId: "",
        init() {

            const component = this.$wire;
            setTimeout(() => {
                this.drawChart(component)
            }, 0)

            this.chartId = component.get("chartId");

            window.Livewire.on(`update${this.chartId}`, data => {
                this.drawChart(this.$wire, data);
            });
        },

        drawChart(component, updatedData = null) {
            const data = updatedData || component.get("data");

            let series = [1];
            let labels = ['No data'];
            let colors = ['#f0f0f0'];
            let hasValue = false;

            data.map((row) => {
                if(row.value > 0){
                    hasValue = true;
                }
            })

            if(hasValue){
                series = data.map((item) => item.value);
                labels = data.map(item => item.title)
                colors = component.get("colors");
            }

            const options = {
                series: series,
                chart: {
                    eight: 320,
                    type: 'donut',
                },
                labels: labels,
                colors,
                stroke: {
                    show: false,
                },
                legend: {
                    show: true,
                    position: 'right',
                    horizontalAlign: 'center',
                    fontSize: 12,
                    fontFamily: "var(--f-typography--body-font-family)",
                    fontWeight: 500,
                    lineHeight: 13,
                    labels: {
                        colors: "var(--f-color-grey-500)",
                    }
                },
                dataLabels: {
                    enabled: true,
                    fontFamily: "var(--f-typography--body-font-family)",
                    style: {
                        fontSize: 10,
                        fontWeight: 600,
                        lineHeight: 21
                    },
                    dropShadow: false
                },
                tooltip: {
                    y: {
                        formatter: function (val, opts) {
                            return numberFormat(val);
                        }
                    },
                }
            }

            if (this.chart) {
                this.chart.destroy()
            }

            this.chart = new ApexCharts(
                this.$refs.container,
                mergeChartOptions(this.chartId, options)
            );

            this.chart.render();
        }
    }
}
