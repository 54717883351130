import flatpickr from "flatpickr";

document.addEventListener('alpine:init', () => {
    window.Alpine.data('datepicker', (options) => ({
        open: false,
        picker: null,
        date: options.defaultDate ?? null,
        init() {
            let format = 'd/m/Y';
            if (options.enableTime && options.noCalendar) {
                format = 'H:i';
            } else if (options.enableTime && !options.noCalendar) {
                format = 'd/m/Y - H:i';
            }

            this.$watch('date', val => {
                this.date = val;
                this.picker.setDate(val)
            });

            this.picker = flatpickr(this.$refs.input, {
                dateFormat: 'Y-m-d\\TH:i:S',
                altInput: true,
                altFormat: format,
                onClose:  () => {
                    this.open = false;
                },
                onOpen: () => {
                    this.open = true;
                },
                onChange: (val, dateString) => {
                  this.date = dateString;
                },
                ...options
            });

        }
    }));
});
