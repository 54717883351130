document.addEventListener('alpine:init', () => {
    window.Alpine.data('multiSelect', (initValues, options, disabled, name) => ({
        selectedOptions: [],
        options: options,
        open: false,
        filter: '',
        removing: false,
        disabled: disabled,
        name: name,
        focused: false,
        init() {

            try {
                this.selectedOptions = JSON.parse(initValues) ?? []
            } catch {
                //
            }

            this.options = this.options.map(function (option) {
                return { key: String(option.key), value: option.value }
            })

            this.checkOffscreen();
        },
        getLabel(key) {
            return this.options.find((option) => option.key === key)?.value;
        },
        checkOffscreen() {
            const dropdown = this.$refs.dropdown;
            if (!this.open) {
                dropdown.style = 'display: none;';
                return;
            } else {
                dropdown.style = 'display: block;';
            }

            const input = this.$refs.input;
            const inputRect = input.getBoundingClientRect();
            const dropdownRect = dropdown.getBoundingClientRect();
            const inputBottom = inputRect.top + inputRect.height;
            const distance = getComputedStyle(dropdown).getPropertyValue('--f-multiselect-input--options-wrapper-top-distance');

            if (inputBottom + dropdownRect.height + 8 > window.innerHeight && this.open) {
                dropdown.style = `bottom: calc(${inputRect.height + 10}px + ${distance}); display: block;`;
            }

            if (inputBottom + dropdownRect.height + 8 <= window.innerHeight && this.open) {
                dropdown.style = '';
            }
        },
        toggleDropdown(open) {
            if (this.disabled) {
                return;
            }

            if (this.removing) {
                this.removing = false;
                return;
            }

            if (open !== undefined) this.open = open;
            else this.open = !this.open;

            if (this.open === true) {
                this.$refs.select.focus();
            }

            this.checkOffscreen();
            this.$nextTick(() => this.$refs.input.focus())
        },
        clickOption(optionKey) {
            if (this.disabled) {
                return;
            }

            const i = this.getOptionIndex(optionKey);
            if (i <= -1) {
                this.selectedOptions.push(optionKey);
            } else {
                this.selectedOptions.splice(i, 1);
            }

            this.$refs.select.focus();
        },
        removeOption(optionKey) {
            if (this.disabled) {
                return;
            }

            this.removing = true;
            const i = this.getOptionIndex(optionKey);
            if (i > -1) {
                this.selectedOptions.splice(i, 1);
            }
        },
        getOptionIndex(optionKey) {
            return this.selectedOptions.indexOf(optionKey);
        },
        filterOptions() {
            if (!this.filter) {
                return this.options;
            }

            return this.options.filter((option) => {
                return option?.value.toLowerCase().includes(this.filter?.toLowerCase());
            })
        },

        /**
         * Navigating with arrow keys
         */
        position: 0,
        mouseMoved: true,
        prevOption() {
            const num = this.options?.length;
            if (this.position - 1 < 0) {
                this.position = num - 1;
            } else {
                this.position--;
            }

            if (!this.open) {
                return;
            }

            this.mouseMoved = false;
            this.ensureOptionInView(this.options?.[this.position]?.key);
        },
        nextOption() {
            const num = this.options?.length;
            if (this.position + 1 > num - 1) {
                this.position = 0;
            } else {
                this.position++;
            }

            if (!this.open) {
                return;
            }

            this.mouseMoved = false;
            this.ensureOptionInView(this.options?.[this.position]?.key);
        },
        selectOption() {
            if (this.open) {
                this.clickOption(this.options?.[this.position]?.key);
            } else {
                this.showHideList(true);
            }
        },
        ensureOptionInView(id) {
            const el = document.getElementById(`${name}__${id}`);
            const div = this.$refs.dropdown_options;

            const padding = window.getComputedStyle(div)?.getPropertyValue('padding-top')?.match(/\d+/)[0];

            if (el) {
                div.scrollTo({
                    top: el.offsetTop - el.getBoundingClientRect().height - (padding ?? 0) // Take into account element height + dropdown padding
                });
            }
        },
        fillUsingQueryParameter() {
            const params = new URLSearchParams(document.location.search);
            const value = params.get(`qf_${name}`);
            if (value) {
                this.selectedOptions.push(value);
            }
        }
    }));
});
