document.addEventListener('alpine:init', () => {
    window.Alpine.data('collapsePanel', (open = true) => ({
        open: open,
        hideBg: open,
        timeout: null,
        togglePanel() {
            this.open = !this.open;
            this.hideBg = false;
            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => { if (this.open) this.hideBg = true; }, 150); // @todo: Duration should be base off theme
        }
    }));
});
