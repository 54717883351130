import ApexCharts from "apexcharts";
import {mergeChartOptions, numberFormat} from '../helpers'

export const barChart = (isMoneyChart) => {
    return {
        chart: null,
        chartId: "",
        init() {
            const component = this.$wire;

            setTimeout(() => {
                this.drawChart(component)
            }, 0)

            this.chartId = component.get("chartId")

            window.Livewire.on(`update${this.chartId}`, payload => {
                let {data, axis} = payload;
                this.drawChart(this.$wire, data, axis);
            });
        },

        drawChart(component, updatedData = null, axis = null) {
            const data = updatedData || component.get("data") || [];
            const colors = component.get("colors");
            const horizontal = component.get("orientation") === "horizontal";
            const isMultiColumn = component.get("multiColumn");
            let xAxisCategories = axis || component.get("xAxisCategories") || [];
            const title =  component.get("chartTitle") || "";
            
            let hasData = false;

            if(isMultiColumn){
                data.forEach((item)=>{
                    if(Object.keys(item.data).length > 0){
                        hasData = true;
                    }
                });
            } else {
                if(Object.keys(data).length > 0){
                    hasData = true;
                }
            }

            if(axis !== null){
                xAxisCategories = axis;
            }

            let series = [];

            if (xAxisCategories.length === 0) {
                xAxisCategories = data.map((item) => item.title)
            }

            if (isMultiColumn) {
                series = data
            } else {
                series = [
                    {
                        name: title,
                        data: data,
                    },
                ];
            }

            const optimalColumnWidthPercent = 40 + (60 / (1 + 30*Math.exp(-series.length /3)));

            if(!hasData){
                series = [];
                xAxisCategories = [];
            }

            const options = {
                series,

                chart: {
                    type: "bar",
                    height: "100%",

                    toolbar: { show: false },

                    animations: { enabled: true },
                },
                plotOptions: {
                    bar: {
                        horizontal,
                        borderRadius: 0,
                        columnWidth: optimalColumnWidthPercent + "%"
                    },
                },

                dataLabels: {
                    enabled: false
                },

                yaxis: {
                    show: true,
                    title,
                    labels: {
                        offsetX: 0,
                        style: {
                            colors: "var(--f-color-grey-500)",
                            fontFamily: "var(--f-typography--body-font-family)",
                            fontWeight: 400,
                            fontSize: 10,
                            lineHeight: 16
                        },
                    },
                },
                xaxis: {
                    labels: {
                        style: {
                            colors: "var(--f-color-grey-500)",
                            fontFamily: "var(--f-typography--body-font-family)",
                            fontWeight: 400,
                            fontSize: 10,
                            lineHeight: 16
                        },
                    },
                    axisTicks: {
                        show: false,
                    },
                    categories: xAxisCategories,
                    axisBorder: {
                        show: false
                    }
                },
                colors,
                legend: {
                    show: false
                },
                fill: {
                    opacity: 1,
                    type: 'solid',
                },
                // affects width and spacing of bar charts (used to add some spacing on grouped/multi column bar chart)
                stroke: {
                    colors: ['transparent'],
                    width: 4,
                },
                grid: {
                    borderColor: '#e6e8ef',
                    xaxis: {
                        lines: {
                            show: horizontal
                        },
                    },
                    yaxis: {
                        lines: {
                            show: !horizontal
                        }
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val, opts) {
                            if (isMoneyChart) {
                                return numberFormat(val, true);
                            }

                            return val;
                        }
                    }
                }
            };

            if(!hasData){
                options['noData'] = {
                    text: 'No data',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        colors: "var(--f-color-grey-500)",
                        fontFamily: "var(--f-typography--body-font-family)",
                        fontWeight: 400,
                        fontSize: 15,
                        lineHeight: 16
                    }
                }
            }

            if (this.chart) {
                this.chart.destroy()
            }

            this.chart = new ApexCharts(
                this.$refs.container,
                mergeChartOptions(this.chartId, options)
            );
            this.chart.render();
        },
    };
};
