document.addEventListener('alpine:init', () => {
    window.Alpine.data('autocomplete', (isSelect, name, selected = []) => ({
        selected: selected, // .e.g [ { key: 1, value: 'Gray Ltd' } ]
        search: '',
        results: [],
        loading: false,
        initiallySearched: false, // To ensure we only show 'No results found' after we have tried searching once
        open: false,
        name: name,
        // getResults (must be provided in parent)
        selectResult(key, value) {
            if (this.isSelected(key)) {
                this.removeResult(key);
            } else {
                // Behaviour as a Select component
                if (isSelect) {
                    this.toggleDropdown(false);
                    this.search = '';

                    this.selected = [{
                        key: key,
                        value: value
                    }];
                    return;
                }
                this.selected.push({
                    key: key,
                    value: value
                });
            }
        },
        removeResult(key) {
            this.selected = this.selected.filter(item => item.key !== key);
        },
        isSelected(key) {
            return this.selected.find(item => item.key === key) !== undefined;
        },
        toggleDropdown(isOpen) {
            if (isOpen !== undefined) {
                this.open = isOpen;
            } else {
                this.open = !this.open;
            }

            if (!this.open) {
                this.results = [];
                this.search = '';
                this.initiallySearched = false;
            } else {
                this.$nextTick(() => this.$refs.input.focus())
            }
        },
        init() {
            if (typeof this.getResults === 'undefined') {
                throw new Error('You must implement getResults to use x-flow-autocomplete');
            }

            this.$watch('search', value => {
                if (this.search !== '') {
                    this.debounceGetResults()(value);
                } else {
                    this.results = [];
                    this.initiallySearched = false;
                    this.loading = false;
                }
            });

            this.autocompleteUsingQueryParameter();
        },
        debounceGetResults() {
            return _.debounce((value, callback = null) => {
                this.loading = true;
                this.getResults(value)
                    .then(results => {
                        if (this.open && results) {
                            this.results = results;
                            this.initiallySearched = true;
                            this.loading = false;
                        }
                    })
                    .then(callback);
            }, 500);
        },
        /**
         * Look for qf_{field} query parameters, run a search, and select them.
         */
        autocompleteUsingQueryParameter() {
            const params = new URLSearchParams(document.location.search);
            const value = params.get(`qf_${this.name}`);
            if (value) {
                this.debounceGetResults()(value, () => {
                    this.selected = [{
                        key: value,
                        value: value
                    }];
                });
            }
        }
    }));
});
