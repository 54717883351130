import axios from "axios";

document.addEventListener('alpine:init', () => {
    window.Alpine.data('fileUpload', (files, limit, disabled, showPreview ) => ({
        files: files || [],
        error: null,
        limit: limit,
        showPreview: showPreview,

        init() {
            if (files) {
                this.files = files;
            }

            this.$refs.content.addEventListener('dragover', () => {
                this.$refs.fileInput.style = "z-index: 999";
            });
            this.$refs.content.addEventListener('dragleave', () => {
                this.$refs.fileInput.style = "";
            });
        },

        addFiles() {
            if (!this.$refs.fileInput.files?.[0] || disabled) return;

            if (limit && this.files?.length >= limit) {
                return;
            }

            Array.from(this.$refs.fileInput.files).forEach(file => {
                let previewUrl = showPreview && file.type.startsWith("image/") ? URL.createObjectURL(file) : null;

                let formData = new FormData();
                formData.append('image', file);

                axios.post('/api/file', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(resp => {
                    this.files.push({
                        name: resp.data.name,
                        fileName: resp.data.fileName,
                        previewUrl: previewUrl
                    });
                }).catch(err => {
                    this.error = {
                        code: err.code,
                        message: err?.response?.data?.error || err?.response?.statusText
                    };
                });
            });

            this.$refs.fileInput.value = null;
            this.$refs.fileInput.style = "";
        },

        removeFile(fileToRemove) {
            if (disabled) return;

            this.files = this.files.filter(file => {
                if (file.fileName === fileToRemove.fileName && file.previewUrl) {
                    URL.revokeObjectURL(file.previewUrl);  // Revoke preview URL to free memory
                }
                return file.fileName !== fileToRemove.fileName;
            });
        }
    }));
});
