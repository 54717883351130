import deepmerge from 'deepmerge'

export const mergeChartOptions = (chartUniqueKey, defaultOptions) => {
    const globalConfig = window.abGroupChartConfig || {}
    const chartOptions = globalConfig[chartUniqueKey]

    if (!chartOptions) {
        return defaultOptions
    }

    return deepmerge(defaultOptions, chartOptions)
}

// Comma separate a number - similar to PHP numberFormat(1000) = 1,000
export const numberFormat = (value, withSign = false)  => {
    if (!withSign) {
        return Number(value).toLocaleString('en', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    }

    let options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    };

    if (value >= 0) {
        return '£' + Number(value).toLocaleString('en', options)
    }

    return '-£' + Number(Math.abs(value)).toLocaleString('en', options)
}
