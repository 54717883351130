import axios from 'axios'

async function toggleUserStatusRequest(userId, toggle) {
    let type = '';
    let text = '';

    try {
        const response = await axios.post(`/api/block-user/${userId}`)

        toggle.checked = !response.data.status;

        let state = 'unblocked';
        if(!response.data.status){
            state = 'blocked'
        }
        type = 'success';
        text = `User has successfully been ${state}`;
    } catch (e) {
        if (e.response.status === 403) {
            type = 'error';
            text = 'You are not authorized to edit a users status';
        } else {
            type = 'error';
            text = 'An error has occurred, please try again later.';
        }
        toggle.checked = !toggle.checked;
    }

    return {type, text};
}

window.toggleUserStatusRequest = toggleUserStatusRequest;
