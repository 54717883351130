import { position } from 'caret-pos';

function getIndicesOf(searchStr, str) {
    var searchStrLen = searchStr.length;
    if (searchStrLen === 0) {
        return [];
    }
    var startIndex = 0, index, indices = [];
    str = str.toLowerCase();
    searchStr = searchStr.toLowerCase();

    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
    }
    return indices;
}

document.addEventListener('alpine:init', () => {
    window.Alpine.data('mentionsTextarea', () => ({
        users: [],
        content: '',

        results: [],
        showResults: false,

        prevChar: null,
        searchInput: '',
        currentNode: null,
        error: null,

        optionIndex: 0,
        isNavigatingOptions: false,

        init() {
            if (typeof this.callback !== 'function') {
                throw new Error('You must implement the callback function to use the Mentions area');
            }

            this.$watch('showResults', () => {
                this.searchInput = '';
                this.optionIndex = 0;
            });
        },

        getResults() {
            this.callback(this.searchInput).then(result => {
                this.results = result;
                this.error = null;
            })
            .catch(e => {
                console.log(e);
                this.error = 'Error';
                this.results = [];
            });
        },

        makeMention(id, name) {
            return `${this.prevChar !== '' ? '&nbsp;' : ''}<span id="${id}" data-name="${name}" contenteditable="false" class="f-mentions-area__badge">${name}</span>&nbsp;`;
        },

        addMention(user) {
            this.users.push(user);

            if (this.currentNode?.innerHTML) {
                const t = this.currentNode.innerHTML;
                this.currentNode.innerHTML = t.substring(0, t.length - 2);
                this.currentNode.innerHTML += this.makeMention(user.key, user.value);
            } else {
                // If a normal text node, we must replace it with a new Div element, so we can place our mention inside
                const t = this.currentNode.textContent;
                const node = document.createElement('div');
                node.innerHTML = t.substring(0, t.length - 2) + this.makeMention(user.key, user.value);
                this.currentNode.replaceWith(node);
            }

            // If we somehow have just a standard Text element, we need to get the parent div (either base contenteditable div or child div)
            if (!this.currentNode?.innerHTML) {
                this.currentNode = document.getElementById(user.key).parentNode;
            }

            this.toggleLock(true);
            this.$refs.div.focus();

            // Reset cursor position
            position(this.currentNode,  this.currentNode.innerText.length);

            this.showResults = false;
            this.updateLocalState();
        },

        update(e) {
            if (this.$refs.div !== document.activeElement) {
                return;
            }

            const latestChar = e.data;
            if (latestChar !== '@') {
                this.updateLocalState();
                return;
            }

            const editableSpan = this.$refs.div;

            // Find the node containing our @ character
            const node = Array.from(editableSpan.childNodes).find(node => {
                const nodeContent = node?.innerText ?? node?.textContent;

                const indices = getIndicesOf('@', nodeContent);
                if (indices.length > 0) {
                    for (let i of indices) {
                        const prevChar = nodeContent.charAt(i - 1);

                        if (!prevChar || prevChar === ' ' || prevChar === ' ') {
                            this.prevChar = prevChar;
                            return true;
                        }
                    }
                }

                this.prevChar = null;
                return false;
            });

            if (node) {
                this.currentNode = node;

                this.showResults = true;
                this.toggleLock(false);

                this.$nextTick(() => this.$refs.searchInput.focus());

                this.getResults();
            }
        },

        toggleLock(editable) {
            this.$refs.div.contentEditable = editable;

            const childNodes = Array.from(this.$refs.div.childNodes);
            for (let node of childNodes) {
                node.contentEditable = editable;
            }
        },

        updateLocalState() {
            this.content = this.$refs.div.innerHTML;
        },

        cancel() {
            this.$refs.div.focus();
            this.showResults = false;
            this.toggleLock(true);

            if (!this.currentNode) {
                return;
            }

            if (this.currentNode?.innerHTML) {
                this.currentNode.innerHTML = this.currentNode.innerHTML.substring(0, this.currentNode.innerHTML.length - 1);
                position(this.currentNode,  this.currentNode.innerText.length);
            } else {
                this.currentNode.textContent = this.currentNode.textContent.substring(0, this.currentNode.textContent.length - 1);
                position(this.currentNode.parentNode,  this.currentNode.textContent.length);
            }
        },

        navigateOptions(direction) {
            this.isNavigatingOptions = true;

            if (direction === 'down') {
                this.optionIndex++;
                if (this.optionIndex > this.results.length - 1) {
                    this.optionIndex = 0;
                }
            } else {
                this.optionIndex--;
                if (this.optionIndex < 0) {
                    this.optionIndex = this.results.length - 1;
                }
            }
        },

        chooseOption() {
            this.addMention(this.results[this.optionIndex]);
        },
    }));
});
