import axios from 'axios'

async function getNotificationCountRequest() {
    let data = {};
    try {
        const response = await axios.get('/api/notification-count')
        data = response.data;

    } catch (e) {

    }

    if(data.notificationCount > 99){
        return '99+'
    }

    return data?.notificationCount?.toString();
}


window.getNotificationCountRequest = getNotificationCountRequest;
