import {livewire_hot_reload} from 'virtual:livewire-hot-reload'
import './bootstrap';
import '../../packages/lightflows/flow-design-system/resources/js/app.js';
import Alpine from 'alpinejs';
import Clipboard from "@ryangjchandler/alpine-clipboard";
import tippy from 'tippy.js';
import './toggleUserStatusRequest';
import './getNotificationCount';
import './charts'
import './formatText';
import './overrideFontSize'
import {numberFormat} from "./charts/helpers";

window.numberFormat = numberFormat;
Alpine.plugin(Clipboard)

window.Alpine = Alpine;
livewire_hot_reload();

Alpine.start();

window.addEventListener('mousemove', () => {
   window.dispatchEvent(new Event('mousehasmoved'));
});

window.addEventListener('scroll', () => {
   window.dispatchEvent(new Event('pagehasscrolled'));
});

// tippyJS
function initTippy() {
    tippy('.js-tippy-target', {
        content(reference) {
            return reference.getAttribute('data-tippy-content');
        },
        placement: 'bottom',
    });
}

// Initialize on page load
document.addEventListener("DOMContentLoaded", initTippy);

// Reinitialize after Livewire updates - allows tippy to be used on modals
Livewire.hook('message.processed', initTippy);
