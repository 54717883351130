window.abGroupChartConfig = window.abGroupChartConfig || {};
const globalConfig =  window.abGroupChartConfig

// An example how specific chart options can be overriden
globalConfig.budgetForecastActuals = {
    tooltip: {
        y: {
            title: {
                formatter: (seriesName) => '',
            },
        },
    }
};
