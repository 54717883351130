function overrideFontSize() {
    if(!window.location.pathname.includes('/near-miss-form')){
        return;
    }

    if (window.innerWidth > 768) {
        return;
    }

    document.documentElement.style.setProperty('--f-input--label-font-size', '20 px');
}

window.overrideFontSize = overrideFontSize;
