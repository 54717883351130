document.addEventListener('alpine:init', () => {
    window.Alpine.data('tagsInput', (tags) => ({
        value: '',
        tags: tags ?? [],
        addTag(e) {
            if (e.key !== 'Enter') {
                return;
            }

            e.preventDefault();

            this.tags.push(this.value);
            this.value = '';
        },
        removeTag(str) {
            this.tags = this.tags.filter(tag => tag !== str);
        }
    }));
});
