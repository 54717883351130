document.addEventListener('alpine:init', () => {
    window.Alpine.data('textArea', () => ({
        render() {
            const currentHeight = this.$el.offsetHeight;
            const scrollHeight = this.$el.scrollHeight;

            // Currently: setting element height to 5px causes page to 'jump' and textarea to focus incorrectly
            // this solves that issue, but I can't find a way to make the textbox shrink again if content is deleted
            // todo this doesn't shrink if content is removed
            if (currentHeight !== scrollHeight) {
                this.$el.style.height = `${scrollHeight}px`;
            }
        }
    }));
});
