
function formatText(str){
    //Replace '_' with ' ' + lower case all letters
    str = str.replace(/_/g, ' ').toLowerCase();

    //Set first letter of each word to be uppercase
    return str.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
    });
}


window.formatText = formatText;
